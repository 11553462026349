<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Blog</h4>
                  <h1>Categorías</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="confirmDeleteAllProducts($event)" v-if="selectedElements.length">
                      <ion-icon :icon="trashOutline" color="danger" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                    <ion-button @click="exportCSV($event)">
                      <ion-icon :icon="cloudDownloadOutline" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ion-button @click="$router.push({ path: `/modules/blog/categories/new` })">
                      <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                @rowReorder="reorderCategories"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <Column v-if="selectedColumns.includes('Imagen')" field="images" header="Imagen" style="flex-grow:1; flex-basis:64px; min-width: 64px;">
                  <template #body="slotProps">
                      <img v-if="slotProps.data.images.length" :src="slotProps.data.images[0]['256']" alt="preview" class="preview" />
                  </template>
                </Column>

                <Column :rowReorder="true" :reorderableColumn="false" style="cursor: move;" />

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Nombre')" field="name.es" header="Nombre" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-primary-field" v-if="slotProps.data.name">{{ slotProps.data.name.es.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descripción')" field="description" header="Descripción">
                  <template #body="slotProps">
                      <span class="table-field" v-if="slotProps.data.description.es">{{ slotProps.data.description['es'].toLowerCase() }}</span>
                  </template>
                </Column>

                <!-- Datos Expandidos -->
                <Column v-if="selectedColumns.includes('Activo')" field="active" header="Activo" :sortable="true">
                  <template #body="slotProps">
                      <ion-chip v-if="slotProps.data.active" color="success"><ion-label >Activo</ion-label></ion-chip>
                      <ion-chip v-if="!slotProps.data.active" color="danger"><ion-label >Inactivo</ion-label></ion-chip>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="activeOptions" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Creación')" field="dateCreated" header="Creación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Modificación')" field="dateUpdated" header="Modificación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateUpdated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.objectID.toLowerCase() }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/blog/categories/' + slotProps.data.objectID">
                        <ion-label>Editar</ion-label>
                      </ion-button>
                  </template>
                </Column>

            </DataTable>
        </section>
      </div>
      <Footer></Footer>

    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonLabel, IonChip } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import ConfirmPopup from 'primevue/confirmpopup';

import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';


export default defineComponent({
  name: 'BlogCategories',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    MultiSelect,
    IonLabel,
    Calendar,
    Dropdown,
    IonChip,
    ConfirmPopup
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: {},

        activeOptions: [
          {
            label: 'Activo',
            value: true,
          },
          {
            label: 'Inactivo',
            value: false,
          },
        ],

        selectedColumns: null,
        columns: [
          'Imagen',
          'Nombre',
          'Descripción',

          'Fecha Creación',
          'Fecha Modificación',
          'Activo',
          'ID'
        ],
      };
    },
    computed: {
      ...mapState('blog', {
        elements: state => state.categories,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('blog',['getCategories', 'addCategory', 'updateCategory', 'deleteCategory']),

      initFilters() {
          this.selectedColumns = [
            'Imagen',
            'Nombre',
            'Descripción',
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'dateUpdated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'active': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      confirmDeleteAllProducts(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar estos elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.selectedElements.forEach(product => {
                      this.deleteCategory(product.objectID);
                    });

                    this.selectedElements = [];
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
      },

      handleEdit(id){
        this.$router.push({ path: `/modules/restaurant/dishes/${id}` })
      },

      confirmDeleteElement(event, id){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                     this.deleteCategory(id);
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      reorderCategories(event){
        const temp = this.elements[event.dragIndex];
        this.elements.splice(event.dragIndex, 1);
        this.elements.splice(event.dropIndex, 0, temp);

        let i = 0;

        this.elements.forEach(element => {
            element.order = i;
            this.updateCategory({data: element, id: element.objectID});

            i = i +1;
        });
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY - HH:mm');
      },
    },
    created(){
      this.getCategories();
      this.initFilters();
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline };
    }
});
</script>

<style scoped>
  .preview{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }
  
</style>